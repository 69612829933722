export const autogiroTypes = {
  account: {
    both: "BOTH",
    peyee: "PAYEE",
    payor: "PAYOR",
  },
  payer: {
    applicant: "APPLICANT",
    co_applicant: "CO_APPLICANT",
  },
};
