import { gql } from "@apollo/client";

export const GET_INTERFACETEXT = gql`
  # Write your query or mutation here
  query interfaceText {
    interfaceText {
      completion {
        loanRepaymentTitle
        loanRepaymentDescription
        loanRepaymentIngress
        loanRepaymentHelp
        # Lånenummer/kreditnummer
        loanRepaymentCardNumberLabel
        loanRepaymentCardNumberPlaceholder
        loanRepaymentCardNumberHelp
        # Beloppet som ska lösas/extraamorteras
        loanRepaymentAmountToRepayLabel
        loanRepaymentAmountToRepayPlaceholder
        loanRepaymentAmountToRepayHelp
        # Bankgiro eller plusgiro
        loanRepaymentBankPlusGiroLabel
        loanRepaymentBankPlusGiroPlaceholder
        loanRepaymentBankPlusGiroHelp
        # Referensnummer/OCR nummer
        loanRepaymentOCRNumberLabel
        loanRepaymentOCRNumberPlaceholder
        loanRepaymentOCRNumberHelp
        # Bank name
        loanRepaymentBankNameLabel
        loanRepaymentBankNameHelp
        loanRepaymentBankNamePlaceholder

        #Other bank's name

        loanRepaymentOtherBankNameLabel
        loanRepaymentOtherBankNamePlaceholder
        loanRepaymentOtherBankNameHelp

        citizenshipTitle
        citizenshipDescription
        citizenshipIngress
        citizenshipHelp

        # I vilka länder har du medborgarskap?
        citizenshipLabel
        citizenshipCountryListHelp
        citizenshipOtherCountryLabel
        citizenshipOtherCountryPlaceholder

        # Ange ditt skatteregistreringsnummer (TIN: Tax Identification Number) i USA
        usCitizenTaxID
        usCitizenTaxIDPlaceholder
        usCitizenTaxIDHelp
        otherTaxDomicileTitle
        otherTaxDomicileDescription
        otherTaxDomicileIngress
        otherTaxDomicileHelp

        # Har du skatterättslig hemvist i annat land än Sverige eller USA?
        otherTaxDomicileHasOtherTaxDomicileLabel
        otherTaxDomicileHasOtherTaxDomicileHelp

        # Ange ditt skatteregistreringsnummer (TIN: Tax Identification Number) i detta land
        otherTaxDomicileCountryListLabel
        otherTaxDomicileCountryListHelp

        otherTaxDomicileOtherCountryLabel
        otherTaxDomicileOtherCountryPlaceholder
        otherTaxDomicileOtherCountryHelp

        otherTaxDomicileTaxIDLabel
        otherTaxDomicileTaxIDHelp

        reviewInfoTitle
        reviewInfoDescription
        reviewInfoIngress
        moreAboutYouTitle
        moreAboutYouDescription
        moreAboutYouHelp
        moreAboutYouIngress
        pepSelfLabel
        pepSelfHelp
        pepContactHelp
        pepContactLabel
        coApplicantPepSelfLabel
        coApplicantPepSelfHelp
        coApplicantPepContactLabel
        coApplicantPepContactHelp

        coApplicantCitizenshipLabel
        coApplicantCitizenshipHelp

        coApplicantUsCitizenTaxIDHelp
        coApplicantUsCitizenTaxID
        coApplicantUsCitizenTaxIDPlaceholder

        coOtherTaxDomicileHasOtherTaxDomicileLabel
        coOtherTaxDomicileCountryListLabel
        coOtherTaxDomicileTaxIDLabel
        coApplicantCitizenshipLabel
        coApplicantUsCitizenTaxID
        coApplicantUsCitizenTaxIDPlaceholder

        # Sätta upp autogiro
        autogiroTitle
        autogiroDescription
        autogiroHelp
        autogiroIngress

        # Info is shown if user declined autogiro setup
        autogiroInfoTitle
        autogiroInfoText

        # Autogiro setup group
        setupAutogiroLegend
        setupAutogiroHelp
        acceptsAutogiroSetupLabel
        declinesAutogiroSetupLabel

        # Applicants group
        autogiroApplicantLegend
        autogiroApplicantHelp

        # Bank accounts group
        accountRepaymentLegend
        accountRepaymentHelp

        #Autogiro consent
        autogiroConsentLabel
        autogiroConsentText
      }
    }
  }
`;
