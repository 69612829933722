import { Fade, Grid, styled } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useEffect } from "react";

const Iframe = styled("iframe")(({ theme }) => ({
  width: "100%",
  height: "70vh",
  border: `1px solid ${theme.palette.neutralColor[20]}`,
  borderRadius: theme.spacing(0.5),
  boxShadow: "none",
  backgroundColor: "#ffffff",
}));

const AUTOGIRO_BASE_URL = process.env.REACT_APP_AUTOGIRO_API_BASE_URL;

const requestAccounts = async authCode => {
  const response = await fetch(AUTOGIRO_BASE_URL, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ authCode: authCode }),
  });

  return response;
};

export const TinkComponent = ({
  authLink,
  authPerson,
  onFetchBankAccounts,
  cancelTink,
  onError,
}) => {
  const [receivedAuthCode, setReceivedAuthCode] = useState(null);

  const receiveMessage = useCallback(
    event => {
      if (event.origin !== "https://link.tink.com") {
        return;
      }

      const { type, data, error } = JSON.parse(event.data);

      if (type === "code") {
        // type differ between products
        // This is the authorization code that should be exchanged for an access token
        const code = data;

        if (code) {
          setReceivedAuthCode(code);
        }
      } else if (type === "error") {
        // Handle error response from Tink Links
        if (error.status === "USER_CANCELLED") {
          cancelTink();
        } else {
          onError(error.message);
        }
      } else if (type === "credentials") {
        // Handle credentials error response from Tink Link
        onError(error.message);
      }
    },
    [cancelTink, onError]
  );

  useEffect(() => {
    window.addEventListener("message", receiveMessage, false);
  }, [receiveMessage]);

  useEffect(() => {
    const getAccountsReport = async code => {
      onError(null);

      var response;
      try {
        response = await requestAccounts(code);

        if (!response.ok || response?.errors) {
          onError(
            response.errors?.message ??
              "Auth error: Tyvärr har ett fel inträffat."
          );
        } else {
          const result = await response.json();
          if (result.data?.accounts.length > 0) {
            onFetchBankAccounts(result.data);
          } else {
            onError("Inget konto för att sätta upp autogiro.");
          }
        }
      } catch (error) {
        onError("Tyvärr har ett fel inträffat.");
      } finally {
        setReceivedAuthCode(null);
      }
    };

    if (receivedAuthCode) {
      getAccountsReport(receivedAuthCode);
    }
  }, [receivedAuthCode, onError, onFetchBankAccounts]);

  return (
    <Fade in={true}>
      <Grid>
        {authPerson !== undefined && authLink && (
          <Iframe src={authLink} style={{ width: "100%", height: "70vh" }} />
        )}
      </Grid>
    </Fade>
  );
};
