import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { autogiroTypes } from "../../../config/autogiroTypes";
import { sanitizedString } from "../../helpers/SanitizedString";
import { HelpTooltip } from "../../HelpTooltip/HelpTooltip";

export const ApplicantsGroup = ({
  applicant,
  coApplicant,
  interfaceText,
  defaultValue,
  onChange,
}) => {
  const [value, setValue] = useState(defaultValue ?? null);

  const handleOnChange = e => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <FormControl component='fieldset' color='secondary'>
      <FormLabel
        component='legend'
        htmlFor='autogiroApplicant'
        id='autogiroApplicantLabel'
      >
        {sanitizedString(interfaceText?.autogiroApplicantLegend)}

        {interfaceText?.autogiroApplicantHelp && (
          <HelpTooltip
            inline
            text={sanitizedString(interfaceText?.autogiroApplicantHelp)}
          />
        )}
      </FormLabel>

      <RadioGroup
        id='autogiroApplicant'
        name='autogiroApplicant'
        aria-label={
          interfaceText?.autogiroApplicantLegend ||
          "Vems bankkonto vill du koppla?"
        }
        onChange={e => {
          handleOnChange(e);
        }}
        defaultValue={defaultValue}
        value={value}
      >
        <FormControlLabel
          value={autogiroTypes.payer.applicant}
          control={<Radio size='small' color='secondary' />}
          label={applicant.firstName.concat(" ", applicant.lastName)}
        />
        <FormControlLabel
          value={autogiroTypes.payer.co_applicant}
          control={<Radio size='small' color='secondary' />}
          label={coApplicant.firstName.concat(" ", coApplicant.lastName)}
        />
      </RadioGroup>
    </FormControl>
  );
};
