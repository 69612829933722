import { gql } from "@apollo/client";

export const GET_COMPLETION_APP = gql`
  query completion {
    completion {
      data {
        extraInformation {
          firstName
          lastName
          personalNumber
        }
        citizenship {
          countries
          otherCountry
        }
        coApplicant {
          extraInformation {
            firstName
            lastName
            personalNumber
          }
          citizenship {
            countries
            otherCountry
          }
          hasOtherTaxResidences
          otherTaxResidences {
            countrycode
            id
            otherCountry
            taxID
          }
          pepContact
          pepSelf
          usCitizen
          usTaxID
        }
        hasCoApplicant
        hasOtherTaxResidences
        loanRepayments {
          bank
          data {
            amountToRepay
            bankName
            bankPlusGiro
            cardNumber
            ocrNumber
            otherBankName
          }
          id
        }
        otherTaxResidences {
          countrycode
          id
          otherCountry
          taxID
        }
        pepContact
        pepSelf
        requiresLoanRepayment
        status
        statusInterfaceText {
          label
          title
          description
        }
        totalAmountToRepay
        minimumAmountToRepay
        approvedAmount
        feesAmount
        usCitizen
        usTaxID
        hasLsbLoanApplication
        hasInternalLoanRepayment
        requiredResolvedInternalAmount
        autogiroSetup
        autogiroInitUrl
        autogiro {
          applicantType
          bankName
          accounts {
            accountNumber
            accountName
            accountType
            entityId
          }
        }
      }
      errors
    }
  }
`;
