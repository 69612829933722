import { styled } from "@mui/system";

export const StepPreamble = styled("div")(({ theme }) => ({
  margin: 0,
  fontSize: "1rem",
  lineHeight: "1.5rem",
  color: theme.palette.neutralColor[70],

  "p:first-of-type": {
    marginTop: 0,
  },

  "p:last-of-type": {
    marginBottom: 0,
  },
}));
