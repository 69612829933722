import { Button } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { LsbInput } from "../../form-components/LsbInput";
import { autogiroInputLabels } from "../../helpers/autogiroInputLabels";

/** */
// styled layout components are used in AutogiroAccount component
/** */

const Root = styled("div")(({ theme }) => ({
  paddingBottom: "24px",
  border: "1px solid rgba(239,240,240, 1)",
  boxShadow: "0px 2px 6px rgba(147,119,119, 0.1)",
  backgroundColor: theme.palette.primaryColor[10],
  marginBottom: theme.spacing(3),

  [theme.breakpoints.up("sm")]: {
    marginBottom: theme.spacing(4),
    borderRadius: "4px",
  },

  [theme.breakpoints.down("xs")]: {
    borderLeft: 0,
    borderRight: 0,
    padding: "0 0 24px 0",
  },
}));

const AutogiroHeading = styled("div")(({ theme }) => ({
  padding: "24px 32px 24px 32px",
  borderBottom: `1px solid ${theme.palette.neutralColor[20]}`,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  [theme.breakpoints.down("sm")]: {
    padding: "24px 20px 24px 20px",
  },
}));

const AutogiroTitle = styled("span")(({ theme }) => ({
  display: "inline-block",
  color: "#fff",
  fontWeight: 700,
  fontSize: theme.spacing(2),
  lineHeight: "24px",
  padding: "2px 16px",
  textAlign: "center",
  whiteSpace: "nowrap",
  borderRadius: "14px",
  backgroundColor: theme.palette.primaryColor[100],

  [theme.breakpoints.up("sm")]: {
    fontSize: theme.spacing(2.25),
  },
}));

const ButtonGroup = styled("div")(({ theme }) => ({
  display: "flex",

  [theme.breakpoints.up("sm")]: {
    justifyContent: "flex-end",
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  fontSize: theme.spacing(1.75),
  padding: `${theme.spacing(1.25)} ${theme.spacing(1)}`,

  [theme.breakpoints.up("sm")]: {
    padding: `${theme.spacing(1.25)} ${theme.spacing(2)}`,
  },
}));

const AutogiroContent = styled("div")(({ theme }) => ({
  padding: "24px 20px 0px 20px",
  overflowX: "hidden",

  [theme.breakpoints.up("sm")]: {
    padding: "24px 32px 0px 32px",
    overflowX: "initial",
  },

  "& .MuiInput-root": {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "100%",
      minWidth: "100%",
      display: "flex",
    },
  },
}));

/** */
// AutogiroAccount component
/** */

export const AutogiroAccount = ({ accounts, onEdit, onRemove }) => {
  return (
    <Root>
      <AutogiroHeading>
        <AutogiroTitle>Val av konto</AutogiroTitle>

        <ButtonGroup>
          <ActionButton variant='text' type='button' onClick={() => onEdit()}>
            Redigera
          </ActionButton>

          <ActionButton
            className='remove-btn'
            variant='text'
            onClick={() => onRemove()}
          >
            Ta bort
          </ActionButton>
        </ButtonGroup>
      </AutogiroHeading>

      <AutogiroContent>
        {accounts.map((account, index) => {
          let texts = autogiroInputLabels(account.accountType);
          let id =
            "autogiroAccount" +
            account.accountType[0].toUpperCase +
            account.accountType.substring[1] +
            index;

          const accountValue = account?.accountName
            ? account?.accountName.concat(" (", account?.accountNumber, ")")
            : account?.accountNumber;

          return (
            <LsbInput
              key={index}
              label={texts?.legend}
              name={id}
              placeholder='Autogirokonto'
              defaultValue={accountValue}
              disabled={true}
            />
          );
        })}
      </AutogiroContent>
    </Root>
  );
};
