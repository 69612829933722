import { Alert, Fade, FormLabel, Grid } from "@mui/material";
import { styled } from "@mui/system";
import _ from "lodash";

import React, {
  useMemo,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { PrimaryButton } from "../../CTAButtons/CTAButtons";
import { BankIDIcon } from "../../SvgIcons/BankIDIcon/BankIDIcon";
import { CompletionContext } from "../../contexts/CompletionContext";
import { ApplicantsGroup } from "./ApplicantsGroup";
import { TinkComponent } from "./TinkComponent";
import { HighlightedBlock } from "../../Layout/HighlightedBlock";
import { AccountsList } from "./AccountsList";
import { AutogiroAccount } from "./AutogiroAccount";
import { autogiroTypes } from "../../../config/autogiroTypes";

/** */
// styled components are used in AccountCheckComponent
/** */
const Button = styled(PrimaryButton)(({ theme }) => ({
  width: "auto",
  margin: 0,

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    maxWidth: "initial",
  },
  "& .MuiButton-endIcon": {
    margin: "0 0 0 0.5rem",
  },

  "& a": {
    color: "inherit",
    textDecoration: "none",
  },
}));

/** */
// AccountCheckComponent
/** */

const initialStateBankAccounts = { bankName: null, accounts: [] };

export const AccountCheckComponent = ({
  accountTypes,
  onRemoveAutogiro,
  onUpdateAutogiro,
}) => {
  const { completionData, interfaceText } = useContext(CompletionContext);
  const [authPerson, setAuthPerson] = useState({});

  const [connectTink, setConnectTink] = useState(false);
  const [showAuthGroup, setShowAuthGroup] = useState(true);

  const [fetchedBankAccounts, setFetchedBankAccounts] = useState({
    ...initialStateBankAccounts,
  });

  const [savedAccounts, setSavedAccounts] = useState({
    ...initialStateBankAccounts,
  });

  const [editAutogiroBankAccount, setEditAutogiroBankAccount] = useState(false);

  const [error, setError] = useState(null);

  const [linkError, setLinkError] = useState(null);

  const applicantSSN = completionData?.extraInformation?.personalNumber ?? null;
  const coApplicantSSN =
    completionData?.coApplicant?.extraInformation?.personalNumber ?? null;

  const handleSelectAuthPerson = useCallback(
    applicant => {
      let person = {
        applicantType: autogiroTypes.payer.applicant,
        personalNumber: null,
      };
      if (
        applicant === autogiroTypes.payer.co_applicant &&
        completionData.hasCoApplicant
      ) {
        person.applicantType = autogiroTypes.payer.co_applicant;
        person.personalNumber = coApplicantSSN;
      } else if (applicant === autogiroTypes.payer.applicant) {
        person.personalNumber = applicantSSN;
      }

      setAuthPerson(person);
    },
    [completionData.hasCoApplicant, applicantSSN, coApplicantSSN]
  );

  // init Account check
  const initAccountCheck = () => {
    setError(null);
    setShowAuthGroup(false);
    setConnectTink(true);
    setEditAutogiroBankAccount(true);
  };

  // get bank accounts via Tink and present them in AccountList
  const handleFetchBankAccounts = accounts => {
    setFetchedBankAccounts(accounts);
    setShowAuthGroup(false);
    setConnectTink(false);
  };

  // close Tink iframe
  const closeTink = () => {
    setConnectTink(false);
    if (savedAccounts.accounts.length === 0) {
      setShowAuthGroup(true);
    } else {
      setShowAuthGroup(false);
      setEditAutogiroBankAccount(false);
    }
    setFetchedBankAccounts({ ...initialStateBankAccounts });
  };

  // user clicks on the button "Redigera"
  const onEditAccount = () => {
    setShowAuthGroup(true);
    setEditAutogiroBankAccount(true);
    setFetchedBankAccounts({ ...initialStateBankAccounts });
  };

  // user clicks on the button "Ta bort"
  const handleRemoveAccount = () => {
    onRemoveAutogiro();
    closeTink();
  };

  // Tink returns error
  const onTinkError = error => {
    setError(error);

    if (error) {
      setConnectTink(false);
      closeTink();
    }
  };

  const getId = (value, array) => {
    let savedAccount = array.find(item => {
      return item.accountType === value;
    });

    return savedAccount?.entityId ?? null;
  };

  const handleSaveAccounts = chosenAccounts => {
    let tempChosen = [];
    for (const [key, value] of Object.entries(chosenAccounts)) {
      let chosenAccount = fetchedBankAccounts.accounts.find(account => {
        return account.accountNumber === value ? account : null;
      });

      let id =
        savedAccounts?.accounts.length > 0
          ? getId(key, savedAccounts.accounts)
          : null;

      let temp = _.cloneDeep(chosenAccount);
      temp.accountType = key;
      temp.entityId = id;

      tempChosen.push(temp);
    }

    setSavedAccounts({
      bankName: fetchedBankAccounts.bankName,
      accounts: tempChosen,
    });
    setEditAutogiroBankAccount(false);
    onUpdateAutogiro(
      { bankName: fetchedBankAccounts.bankName, accounts: tempChosen },
      authPerson.applicantType
    );
  };

  const authLink = useMemo(() => {
    const link = completionData.autogiroInitUrl ?? null;

    if (link) {
      if (
        process.env.REACT_APP_ENV === "production" ||
        process.env.REACT_APP_ENV === "staging"
      ) {
        return authPerson?.personalNumber
          ? link + "&input_username=" + authPerson?.personalNumber
          : link;
      } else {
        return link;
      }
    } else {
      setLinkError("Ett okänt fel har inträffat. Vänligen försök igen senare.");
    }
  }, [authPerson?.personalNumber, completionData?.autogiroInitUrl]);

  useEffect(() => {
    if (!completionData.hasCoApplicant) {
      handleSelectAuthPerson("APPLICANT");
    }
  }, [completionData.hasCoApplicant, handleSelectAuthPerson]);

  useEffect(() => {
    if (completionData?.autogiro) {
      setSavedAccounts({
        bankName: completionData.autogiro.bankName,
        accounts: completionData.autogiro.accounts,
      });
      setShowAuthGroup(false);

      if (completionData?.autogiro?.applicantType) {
        handleSelectAuthPerson(completionData?.autogiro?.applicantType);
      }
    }
  }, [completionData?.autogiro, handleSelectAuthPerson]);

  if (linkError) {
    return (
      <Fade in={true} timeout={{ enter: 700 }}>
        <Grid item xs={12} md={8} lg={6}>
          <Alert severity='error' sx={{ marginBottom: "2rem" }}>
            <div>{linkError}</div>
          </Alert>
        </Grid>
      </Fade>
    );
  }

  return (
    <Fade in={true} timeout={{ enter: 700 }}>
      <div>
        {error && (
          <Grid item xs={12} md={8} lg={6}>
            <Alert
              severity='error'
              sx={{ marginBottom: "2rem" }}
              onClose={() => setError(null)}
            >
              <div>{error}</div>
            </Alert>
          </Grid>
        )}

        {connectTink ? (
          <Grid item>
            <TinkComponent
              authLink={authLink}
              authPerson={authPerson}
              onFetchBankAccounts={handleFetchBankAccounts}
              cancelTink={closeTink}
              onError={onTinkError}
            />
          </Grid>
        ) : (
          <>
            {!showAuthGroup && (
              <Grid item xs={12} md={8} lg={6}>
                {editAutogiroBankAccount &&
                  fetchedBankAccounts?.accounts.length > 0 && (
                    <AccountsList
                      accountTypes={accountTypes}
                      accounts={fetchedBankAccounts.accounts}
                      interfaceText={interfaceText}
                      onCancel={closeTink}
                      onSaveAutogiro={handleSaveAccounts}
                    />
                  )}
                {!editAutogiroBankAccount &&
                  savedAccounts?.accounts.length > 0 && (
                    <AutogiroAccount
                      accounts={savedAccounts.accounts}
                      onEdit={onEditAccount}
                      onRemove={handleRemoveAccount}
                    />
                  )}
              </Grid>
            )}

            {showAuthGroup && (
              <Grid item xs={12} md={8} lg={6}>
                <HighlightedBlock className='contained'>
                  {completionData?.hasCoApplicant && (
                    <ApplicantsGroup
                      applicant={completionData.extraInformation}
                      coApplicant={completionData.coApplicant.extraInformation}
                      interfaceText={interfaceText}
                      defaultValue={authPerson?.applicantType ?? null}
                      onChange={handleSelectAuthPerson}
                    />
                  )}

                  {!completionData?.hasCoApplicant && (
                    <FormLabel
                      sx={{ display: "block", marginBottom: "1.5rem" }}
                    >
                      Välj ett konto
                    </FormLabel>
                  )}

                  <Button
                    variant='contained'
                    endIcon={
                      <BankIDIcon
                        disabled={authPerson?.personalNumber === undefined}
                        inverted='inverted'
                        small='small'
                      />
                    }
                    type='button'
                    onClick={() => initAccountCheck()}
                    disabled={authPerson?.personalNumber === undefined}
                  >
                    Identifiera dig och fortsätt
                  </Button>
                </HighlightedBlock>
              </Grid>
            )}
          </>
        )}
      </div>
    </Fade>
  );
};
