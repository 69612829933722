import { PrimaryButton } from "../../CTAButtons/CTAButtons";

import React from "react";
import { Spinner } from "../../misc/Spinner/Spinner";
import { Heading } from "../../misc/Typography/Heading";
import { Paragraph } from "../../misc/Typography/Paragraph";
import { BankIDIcon } from "../../SvgIcons/BankIDIcon/BankIDIcon";

import { styled } from "@mui/material";

const LoginHeading = styled(Heading)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    fontSize: "26px",
  },
}));

export const ProcessingLoginScreen = ({
  title,
  message,
  handleCancelLogin,
}) => {
  return (
    <div style={{ textAlign: "center" }}>
      <BankIDIcon mb={1.25} />

      <LoginHeading>{title}</LoginHeading>

      <Paragraph textLight>{message}</Paragraph>

      <Spinner />
      <PrimaryButton
        variant='contained'
        type='submit'
        onClick={() => handleCancelLogin()}
      >
        Avbryt
      </PrimaryButton>
    </div>
  );
};
