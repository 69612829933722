import { styled } from "@mui/material";

export const ConsentBlock = styled("div")(({ theme }) => ({
  backgroundColor: "#F9F7F6",
  padding: `${theme.spacing(3.5)} ${theme.spacing(5)}`,
  border: "1px solid #F2D8D4",
  borderRadius: "4px",

  [theme.breakpoints.down("sm")]: {
    border: 0,
    borderRadius: 0,
    padding: `${theme.spacing(3.5)} ${theme.spacing(2.5)}`,
  },
}));
