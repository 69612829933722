import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useContext } from "react";
import { Controller, FormProvider } from "react-hook-form";
import { CompletionContext } from "../../contexts/CompletionContext";
import { sanitizedString } from "../../helpers/SanitizedString";
import { HelpTooltip } from "../../HelpTooltip/HelpTooltip";

export const SetupAutogiroFieldset = props => {
  const { inReview, formMethods, handleRemoveAutogiro } = props;
  const { interfaceText, completionData } = useContext(CompletionContext);

  const { control } = formMethods;

  const setupAutogiroDefaultValue =
    completionData?.autogiroSetup === true
      ? "yes"
      : completionData?.autogiroSetup === null
      ? null
      : "no";

  const handleChange = value => {
    if (value === "no") {
      handleRemoveAutogiro();
    }
  };

  return (
    <FormProvider {...formMethods}>
      <Grid item xs={12} sm={10} md={7} lg={6}>
        <Controller
          name='autogiroSetup'
          control={control}
          defaultValue={setupAutogiroDefaultValue}
          render={({ field: { onChange, value, name } }) => {
            return (
              <FormControl
                component='fieldset'
                disabled={inReview}
                color='secondary'
              >
                <FormLabel
                  component='legend'
                  htmlFor='autogiroSetup'
                  id='acceptedAutogiroLabel'
                >
                  {sanitizedString(interfaceText?.setupAutogiroLegend)}

                  {!inReview && interfaceText?.setupAutogiroHelp && (
                    <HelpTooltip
                      inline
                      text={sanitizedString(interfaceText?.setupAutogiroHelp)}
                    />
                  )}
                </FormLabel>

                <RadioGroup
                  id='autogiroSetup'
                  name={name}
                  aria-label={
                    interfaceText?.setupAutogiroLegend ||
                    "Önskar du att använda autogiro?"
                  }
                  onChange={e => {
                    onChange(e);
                    handleChange(e.target.value);
                  }}
                  value={value}
                >
                  <FormControlLabel
                    value='yes'
                    control={<Radio size='small' color='secondary' />}
                    label={
                      interfaceText?.acceptsAutogiroSetupLabel ||
                      "Ja, och jag sätter gärna upp det nu"
                    }
                  />
                  <FormControlLabel
                    value='no'
                    control={<Radio size='small' color='secondary' />}
                    label={
                      interfaceText?.declinesAutogiroSetupLabel ||
                      "Nej, kanske senare"
                    }
                  />
                </RadioGroup>
              </FormControl>
            );
          }}
        />
      </Grid>
    </FormProvider>
  );
};
