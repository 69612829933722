import React from "react";
import { SanitizedHtml } from "../helpers/SanitizedHtml";
import { styled } from "@mui/material";

const Root = styled("div")(({ theme }) => ({
  fontWeight: 400,
  fontSize: "15px",
  color: theme.palette.neutral.main,
  lineHeight: "1.5rem",

  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
  },

  "& a": {
    color: theme.palette.primary.main,
  },
}));

export const ModalContent = ({ terms }) => {
  return (
    <Root>
      <SanitizedHtml html={terms} tags={["p", "h3", "h4", "a"]} />
    </Root>
  );
};
