import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  FormLabel,
  Checkbox,
  FormControl,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { useState } from "react";
import { Controller, FormProvider } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { TextualLinkButton } from "../CTAButtons/TextualLinkButton";
import { ModalContent } from "../ConsentModalContent/ModalContent";

const ConsentDialog = styled(Dialog)(({ theme }) => ({
  zIndex: 1400,
}));

const Paragraph = styled("p")(({ theme }) => ({
  fontSize: theme.spacing(2),
  lineHeight: theme.spacing(3),
  color: theme.palette.neutralColor[70],
  marginTop: 0,
  marginBottom: theme.spacing(3),
}));

const FormErrorMessage = styled(FormHelperText)(({ theme }) => ({
  color: "#f44336",
  fontWeight: 700,
  marginTop: 0,
  whiteSpace: "nowrap",
}));

const Root = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(1),

  "& + div": {
    marginBottom: 0,
  },

  "&:last-of-type": {
    marginBottom: 0,
  },
}));

const CheckboxFormGroup = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  marginLeft: "-4px",

  "& .MuiFormControl-root": {
    minWidth: "auto",
    marginBottom: 0,
  },

  "& .MuiCheckbox-root": {
    padding: "0 8px 0 0",
  },
}));

const Label = styled("span")(({ theme }) => ({
  fontWeight: 700,
}));

export const ConsentControl = ({
  fieldName,
  label,
  ingress,
  formMethods,
  handleOnChange,
  dialogText,
  dialogTitle,
  dialogToggler,
}) => {
  const { formState, control } = formMethods;
  const { errors } = formState;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Root>
      {ingress && (
        <Paragraph>
          {ingress}
          {dialogToggler && (
            <>
              &nbsp;
              <TextualLinkButton
                type='button'
                onClick={handleClickOpen}
                sx={{ padding: 0 }}
              >
                {dialogToggler}
              </TextualLinkButton>
            </>
          )}
        </Paragraph>
      )}

      <FormProvider {...formMethods}>
        <CheckboxFormGroup>
          <Controller
            control={control}
            name={fieldName}
            defaultValue={false}
            render={({ field: { name, value, onChange } }) => (
              <FormControl>
                <Checkbox
                  disableRipple
                  color='secondary'
                  checked={value}
                  id={fieldName}
                  name={name}
                  onChange={e => {
                    onChange(e);
                    handleOnChange(e);
                  }}
                  required
                />
              </FormControl>
            )}
          />

          <FormLabel htmlFor={fieldName} sx={{ paddingBottom: 0 }}>
            <Label>
              {label
                ? label
                : "Jag intygar att samtliga angivna uppgifter är korrekta och sanningsenliga."}
            </Label>
            <ErrorMessage
              errors={errors}
              name={fieldName}
              render={({ message }) => (
                <FormErrorMessage>{message}</FormErrorMessage>
              )}
            />
          </FormLabel>
        </CheckboxFormGroup>

        {dialogText && (
          <ConsentDialog
            open={open}
            onClose={handleClose}
            scroll={"paper"}
            aria-labelledby='scroll-dialog-title'
            aria-describedby='scroll-dialog-description'
          >
            <DialogTitle id='scroll-dialog-title'>{dialogTitle}</DialogTitle>
            <DialogContent
              dividers
              id='scroll-dialog-description'
              tabIndex={-1}
            >
              <ModalContent terms={dialogText} />
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color='primary'
                  variant='contained'
                >
                  Stäng
                </Button>
              </DialogActions>
            </DialogContent>
          </ConsentDialog>
        )}
      </FormProvider>
    </Root>
  );
};
