import * as yup from "yup";

export const autogiroValidationSchema = yup.object().shape({
  autogiroSetup: yup.string().nullable().required(),
  autogiro: yup.object({}).when("autogiroSetup", {
    is: "yes",
    then: schema =>
      schema
        .shape({
          bankName: yup.string().when("autogiroSetup", {
            is: "yes",
            then: schema => schema.required(),
            otherwise: schema => schema.required().nullable().notRequired(),
          }),
          applicantType: yup.string().when("autogiroSetup", {
            is: "yes",
            then: schema =>
              schema.oneOf(["APPLICANT", "CO_APPLICANT"]).required(),
            otherwise: schema => schema.nullable().notRequired(),
          }),
          accounts: yup.array().when("autogiroSetup", {
            is: "yes",
            then: schema =>
              schema.object({
                accountNumber: yup.string().required(),
                accountType: yup
                  .string()
                  .oneOf(["BOTH", "PAYEE", "PAYOR"])
                  .required(),
              }),
            otherwise: schema => schema.nullable().notRequired(),
          }),
        })
        .required(),
    otherwise: schema => schema.nullable().notRequired(),
  }),
});
