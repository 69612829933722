import { styled } from "@mui/system";
import Stepper, { stepperClasses } from "@mui/material/Stepper";

export const LsbStepper = styled(Stepper)(({ theme }) => ({
  [`&.${stepperClasses.root}`]: {
    padding: 0,
  },

  [`&.${stepperClasses.horizontal}`]: {
    justifyContent: "space-between",
    marginLeft: "-28px",
    marginRight: "-28px",

    [theme.breakpoints.between(390, 499)]: {
      marginLeft: "-32px",
      marginRight: "-32px",
    },

    [theme.breakpoints.between(500, 599)]: {
      marginLeft: "-52px",
      marginRight: "-52px",
    },

    [theme.breakpoints.up("sm")]: {
      marginLeft: "calc(-13.5% - 5px)",
      marginRight: "calc(-13.5% - 5px)",
    },

    [theme.breakpoints.up("lg")]: {
      marginLeft: "calc(-13.5% - 15px)",
      marginRight: "calc(-13.5% - 15px)",
    },
  },

  "&.steps-count--5": {
    [theme.breakpoints.between(375, 412)]: {
      marginLeft: "-16px",
      marginRight: "-16px",
    },

    [theme.breakpoints.between(412, 499)]: {
      marginLeft: "-18px",
      marginRight: "-18px",
    },
    [theme.breakpoints.between(500, 600)]: {
      marginLeft: "-40px",
      marginRight: "-40px",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "-10.3%",
      marginRight: "-10.3%",
    },

    [theme.breakpoints.up("md")]: {
      marginLeft: "-10.5%",
      marginRight: "-10.5%",
    },

    [theme.breakpoints.up("lg")]: {
      marginLeft: "-11%",
      marginRight: "-11%",
    },
  },

  "&.steps-count--6": {
    [theme.breakpoints.down(412)]: {
      marginLeft: "-10px",
      marginRight: "-10px",
    },

    [theme.breakpoints.between(412, 499)]: {
      marginLeft: "-15px",
      marginRight: "-15px",
    },
    [theme.breakpoints.between(500, 600)]: {
      marginLeft: "-24px",
      marginRight: "-24px",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "-10.3%",
      marginRight: "-10.3%",
    },

    [theme.breakpoints.up("md")]: {
      marginLeft: "-10.5%",
      marginRight: "-10.5%",
    },

    [theme.breakpoints.up("lg")]: {
      marginLeft: "-11%",
      marginRight: "-11%",
    },
  },
}));
