import * as yup from "yup";

// Set the default messages to display when doing validation with yup.
yup.setLocale({
  mixed: {
    default: "Vänligen fyll i fält korrekt",
    required: "Vänligen fyll i fält korrekt",
    notType: "Vänligen fyll i fält korrekt",
    test: "Vänligen fyll i fält korrekt",
  },

  string: {
    email: "Måste vara en korrekt e-post adress",
  },
});
