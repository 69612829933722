import { styled } from "@mui/system";

export const TextualLinkButton = styled("button")(({ theme }) => ({
  appearance: "none",
  border: 0,
  backgroundColor: "transparent",
  display: "inline-block",
  letterSpacing: "0.3px",
  fontWeight: 600,
  lineHeight: "1.125rem",
  color: theme.palette.neutral.lighter,
  transition: "color 0.3s cubic-bezier(0.895, 0.03, 0.685, 0.22)",
  cursor: "pointer",
  textAlign: "left",

  "&:focus-visible": {
    outline: `2px solid ${theme.palette.blueColor}`,
  },

  "&:visited": {
    color: theme.palette.neutral.lighter,
  },

  "&:hover": {
    "&:after": {
      backgroundColor: theme.palette.primaryColor[90],
    },
  },

  "&:after": {
    display: "block",
    content: "''",
    width: "100%",
    height: "2px",
    backgroundColor: theme.palette.primaryColor[40],
    transition: "background-color 300ms ease-in-out",
  },
}));
