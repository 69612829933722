import { yupResolver } from "@hookform/resolvers/yup";
import { Fade, Grid } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useRef } from "react";
import { useContext } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useUpdateCompletionData } from "../../graphql/useUpdateCompletionData";
import { CompletionContext } from "../contexts/CompletionContext";
import { SanitizedHtml } from "../helpers/SanitizedHtml";
import { sanitizedString } from "../helpers/SanitizedString";
import { HelpTooltip } from "../HelpTooltip/HelpTooltip";
import { Section } from "../Layout/Section";
import { StepContent } from "../Layout/StepContent";
import { Alert } from "../misc/Alert/Alert";
import { Spinner } from "../misc/Spinner/Spinner";
import { StepHeading } from "../misc/Typography/StepHeading";
import { StepPreamble } from "../misc/Typography/StepPreamble";
import { AccountCheckComponent } from "./components/AccountCheckComponent";
import { SetupAutogiroFieldset } from "./components/SetupAutogiroFieldset";
import { autogiroValidationSchema } from "./validationSchemaAutogiroStep";
import { StepSectionPaddingTop } from "../../styles/ApplicationFormComponents";

export const AutogiroStep = props => {
  const {
    stepIndex,
    disableCheckStep,
    checkCompleted,
    handleMove,
    setDisableMove,
  } = props;

  const accountTypes = ["BOTH"]; // future feature ["PAYOR", "PAYEE"]

  const [fadeIn, setfadeIn] = useState(false);

  const {
    handleStepsCompleted,
    stepsCompleted,
    completionData,
    interfaceText,
    completionInput,
  } = useContext(CompletionContext);

  const [loading, setLoading] = useState(false);

  const updateCompletionData = useUpdateCompletionData();

  const stepRef = useRef(false);

  const formMethods = useForm({
    mode: "onChange",
    defaultValues: {
      autogiroSetup:
        completionData?.autogiroSetup === true
          ? "yes"
          : completionData?.autogiroSetup === null
          ? null
          : "no",
      autogiro: completionData?.autogiro
        ? {
            applicantType: completionData?.autogiro?.applicantType,
            bankName: completionData?.autogiro?.bankName,
            accounts: completionData?.autogiro?.accounts,
          }
        : null,
    },
    resolver: yupResolver(autogiroValidationSchema),
  });

  const { formState, watch, setValue, getValues } = formMethods;

  const declinedAutogiro = watch("autogiroSetup", null) === "no" ? true : false;

  const acceptedAutogiro =
    watch("autogiroSetup", null) === "yes" ? true : false;

  const toggleCompleted = useCallback(
    completed => {
      handleStepsCompleted(stepIndex, completed);
    },
    [handleStepsCompleted, stepIndex]
  );

  const getAutogiroInput = useCallback(() => {
    const autogiroData = getValues();

    completionInput.autogiroSetup =
      autogiroData?.autogiroSetup === "yes" ? true : false;

    completionInput.autogiro =
      autogiroData?.autogiroSetup === "no" ? null : autogiroData?.autogiro;

    const input = {
      ...completionInput,
    };

    return input;
  }, [getValues, completionInput]);

  const handleRemoveAutogiro = () => {
    setValue("autogiro", null, {
      shouldValidate: true,
      shouldDirty: true,
    });

    setValue("autogiroSetup", "no", {
      shouldValidate: true,
      shouldDirty: true,
    });

    let input = getAutogiroInput();
    sendCompletionData(input);
  };

  const handleUpdateAutogiro = (autogiro, applicantType) => {
    setValue("autogiro.applicantType", applicantType, {
      touched: true,
      shouldValidate: true,
      shouldDirty: true,
    });

    setValue("autogiro.accounts", autogiro.accounts, {
      touched: true,
      shouldValidate: true,
      shouldDirty: true,
    });

    setValue("autogiro.bankName", autogiro.bankName, {
      touched: true,
      shouldValidate: true,
      shouldDirty: true,
    });

    let input = getAutogiroInput();
    sendCompletionData(input);
  };

  const sendCompletionData = useCallback(
    async (data, callback) => {
      try {
        setLoading(true);
        setDisableMove(true);

        const res = await updateCompletionData({
          variables: {
            input: data,
          },
        });

        if (!res?.data?.errors) {
          setLoading(false);
          callback && callback();
        }
      } catch (error) {
      } finally {
        setLoading(false);
        setDisableMove(false);
      }
    },

    [updateCompletionData, setDisableMove]
  );

  useEffect(() => {
    if (stepRef.current) return;

    stepRef.current = true;

    if (interfaceText) {
      setfadeIn(props.fadeIn);
    }

    disableCheckStep();

    return () => {
      stepRef.current = false;
    };
  }, [props.fadeIn, disableCheckStep, interfaceText]);

  useEffect(() => {
    toggleCompleted(declinedAutogiro || formState.isValid);
  }, [formState.isValid, toggleCompleted, declinedAutogiro]);

  useEffect(() => {
    if (checkCompleted && stepsCompleted[stepIndex]) {
      let input = getAutogiroInput();

      sendCompletionData(input, handleMove);
    }
  }, [
    checkCompleted,
    stepIndex,
    stepsCompleted,
    sendCompletionData,
    handleMove,
    getAutogiroInput,
  ]);

  return (
    <Fade in={fadeIn} timeout={{ enter: 500, exit: 500 }}>
      <div>
        <StepContent>
          {loading && <Spinner overlay />}

          <Section>
            <Grid item xs={12}>
              <StepHeading
                title={<SanitizedHtml html={interfaceText?.autogiroTitle} />}
                helpText={
                  interfaceText?.autogiroHelp && (
                    <HelpTooltip
                      text={sanitizedString(interfaceText?.autogiroHelp)}
                    />
                  )
                }
              />
            </Grid>

            <Grid item sm={8}>
              <StepPreamble>
                <SanitizedHtml
                  html={interfaceText?.autogiroDescription}
                  tags={["p"]}
                />
              </StepPreamble>
            </Grid>
          </Section>

          <StepSectionPaddingTop>
            <form>
              <Section>
                <Grid item xs={12}>
                  <SetupAutogiroFieldset
                    {...props}
                    formMethods={formMethods}
                    handleRemoveAutogiro={handleRemoveAutogiro}
                  />

                  {acceptedAutogiro && (
                    <AccountCheckComponent
                      accountTypes={accountTypes}
                      onRemoveAutogiro={handleRemoveAutogiro}
                      onUpdateAutogiro={handleUpdateAutogiro}
                    />
                  )}

                  {declinedAutogiro && (
                    <Fade in={declinedAutogiro} timeout={{ enter: 700 }}>
                      <Grid item xs={12} md={9} lg={8}>
                        <Alert
                          title={interfaceText?.autogiroInfoTitle}
                          message={interfaceText?.autogiroInfoText}
                          type='info'
                        />
                      </Grid>
                    </Fade>
                  )}
                </Grid>
              </Section>
            </form>
          </StepSectionPaddingTop>
        </StepContent>
      </div>
    </Fade>
  );
};
