import React, { useEffect, useState, useContext, useCallback } from "react";
import { Fade, Grid, Container, useMediaQuery, useTheme } from "@mui/material";

import { Controller, FormProvider, useForm } from "react-hook-form";

import { Section } from "../Layout/Section";
import { LoanFormControls } from "../step_loansToResolve/components/LoanFormControls";
import { CitizenshipFormControls } from "../step_citizenship/components/CitizenshipFormControls";
import { CoApplicantCitizenshipFormControls } from "../step_citizenship/coApplicant/CoApplicantCitizenshipFormControls";
import { CoApplicantTaxesRadioButtonsGroup } from "../step_otherCountriesTaxes/coApplicant/components/CoApplicantTaxesRadioButtonsGroup";
import { CoApplicantTaxesFormControls } from "../step_otherCountriesTaxes/coApplicant/components/CoApplicantTaxesFormControls";
import { TaxesFormControls } from "../step_otherCountriesTaxes/components/TaxesFormControls";
import { ReviewBlock } from "../Layout/ReviewBlock";
import { SanitizedHtml } from "../helpers/SanitizedHtml";
import { CompletionContext } from "../contexts/CompletionContext";
import { MoreAboutYouFormComponent } from "../step_MoreAboutYou/components/MoreAboutYouFormComponent";
import { CoApplicantSection } from "../Layout/CoApplicantSection";
import { CoApplicantMoreAboutYouFormComponent } from "../step_MoreAboutYou/coApplicant/components/CoApplicantMoreAboutYouFormComponent";
import { TaxesRadioButtonsGroup } from "../step_otherCountriesTaxes/components/TaxesRadioButtonsGroup";
import { getValidationSchemaReviewStep } from "./validationSchemaReviewStep";
import { useUpdateCompletionData } from "../../graphql/useUpdateCompletionData";
import { StepHeading } from "../misc/Typography/StepHeading";
import { sanitizedString } from "../helpers/SanitizedString";
import { StepPreamble } from "../misc/Typography/StepPreamble";
import { StepContent } from "../Layout/StepContent";
import { HelpTooltip } from "../HelpTooltip/HelpTooltip";
import { Spinner } from "../misc/Spinner/Spinner";
import { InternalLoan } from "../step_loansToResolve/components/InternalLoan";
import { LsbInput } from "../form-components/LsbInput";
import { autogiroInputLabels } from "../helpers/autogiroInputLabels";
import { SetupAutogiroFieldset } from "../step_autogiro/components/SetupAutogiroFieldset";
import { autogiroTypes } from "../../config/autogiroTypes";
import { AutogiroConsent } from "../AutogiroConsent/AutogiroConsent";
import { ConsentBlock } from "../Layout/ConsentBlock";
import { ConsentControl } from "../ConsentControl/ConsentControl";
import { StepSectionPaddingTop } from "../../styles/ApplicationFormComponents";

const AutogiroController = ({ control, autogiro }) => {
  return (
    <Controller
      name='autogiro'
      control={control}
      defaultValue={autogiro}
      render={({ field, name }) => {
        return (
          autogiro !== null &&
          autogiro?.accounts.map((account, index) => {
            let texts = autogiroInputLabels(account.accountType);
            let id =
              "autogiroAccount" +
              account.accountType[0].toUpperCase +
              account.accountType.substring[1] +
              index;

            const accountValue = account?.accountName
              ? account?.accountName.concat(" (", account?.accountNumber, ")")
              : account?.accountNumber;

            return (
              <LsbInput
                key={index}
                label={texts?.legend}
                name={id}
                placeholder='Autogirokonto'
                defaultValue={accountValue}
                disabled={true}
              />
            );
          })
        );
      }}
    />
  );
};

export const ReviewStep = props => {
  const {
    stepIndex,
    classes,
    submitForm,
    toggleFormSubmitted,
    handleFinish,
    setAgreedTerms,
  } = props;

  const [fadeIn, setfadeIn] = useState(false);
  const [finishSteps, setFinishSteps] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    completionData,
    hasLoansToResolve,
    hasLsbLoanApplication,
    handleStepsCompleted,
    interfaceText,
    banksWithExtraFields,
    handleFormCompleted,
    banks,
    setReviewInit,
  } = useContext(CompletionContext);

  const otherBank =
    banks.data.find(bank =>
      bank.loanRepaymentFields.includes("OTHER_BANK_NAME")
    ) || null;

  const hasCoApplicant = completionData?.hasCoApplicant ?? false;

  const validationSchema = getValidationSchemaReviewStep(
    banksWithExtraFields,
    otherBank
  );

  const formMethods = useForm({
    mode: "onChange",
    defaultValues: {
      autogiro: completionData?.autogiro
        ? {
            ...completionData?.autogiro,
            autogiroConsent: false,
            autogiroConsentText: "",
          }
        : null,
    },
    resolver: validationSchema,
  });

  const { control, watch, setError } = formMethods;

  const loanRepayments = completionData?.loanRepayments ?? [];
  const otherTaxResidences = completionData?.otherTaxResidences ?? [];

  const hasInternalLoan = completionData?.hasInternalLoanRepayment ?? false;

  const internalLoanAmount =
    completionData?.requiredResolvedInternalAmount ?? null;

  const pepQuestions =
    completionData?.pepSelf !== null && completionData?.pepContact !== "";

  const autogiro = hasLsbLoanApplication ? completionData?.autogiro : null;

  const autogiroSetup =
    hasLsbLoanApplication && completionData?.autogiroSetup ? "yes" : "no";

  const coApplicant = completionData?.coApplicant ?? {};

  const agreeTerms = watch("termsAndConditionsAgreement", false);

  const autogiroConsent = watch("autogiroConsent", false);

  const handleAgreeChange = e => {
    const { checked, name } = e.target;

    if (name === "termsAndConditionsAgreement") {
      if (autogiroSetup === "yes") {
        if (autogiroConsent) {
          setAgreedTerms(checked);
          return;
        } else {
          setError("autogiroConsent", {
            type: "testConsent",
            message: "Vänligen läs villkor och regler för Autogiro",
          });
          setAgreedTerms(false);
        }
      }

      if (autogiroSetup === "no") {
        setAgreedTerms(checked);
        return;
      }
    }

    if (name === "autogiroConsent") {
      if (agreeTerms) {
        setAgreedTerms(checked);
      } else {
        setAgreedTerms(false);
      }
    }
  };

  const agreedAutogiro = agreed => {
    if (agreeTerms && agreed) {
      setAgreedTerms(true);
    }
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const updateCompletionData = useUpdateCompletionData();

  const sendCompletionData = useCallback(
    async data => {
      try {
        setLoading(true);
        const res = await updateCompletionData({
          variables: {
            input: data,
          },
        });

        if (!res?.data?.errors) {
          setLoading(false);
          handleStepsCompleted(stepIndex, true);
          toggleFormSubmitted(false);
          setFinishSteps(true);
          handleFormCompleted();
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [
      updateCompletionData,
      handleStepsCompleted,
      toggleFormSubmitted,
      setFinishSteps,
      stepIndex,
      handleFormCompleted,
    ]
  );

  const onSubmit = useCallback(
    data => {
      const input = {
        loanRepayments: hasLoansToResolve ? data?.loanRepayments : null,
        citizenship: {
          countries: data?.citizenship?.countries ?? [],
          otherCountry: data?.citizenship?.otherCountry ?? null,
        },
        usCitizen: data?.usCitizen || false,
        usTaxID: data?.usTaxID || null,
        pepSelf: data?.pepSelf,
        pepContact: data?.pepContact === "yes" || false,
        hasOtherTaxResidences:
          data?.hasOtherTaxResidences === "other_countries" || false,
        isComplete: true,
        otherTaxResidences: data?.otherTaxResidences ?? [],
        termsAndConditions: data?.termsAndConditionsAgreement,
        autogiro: data?.autogiro || null,
        autogiroSetup: data?.autogiroSetup === "yes" || false,
        coApplicant: hasCoApplicant
          ? {
              citizenship: {
                countries: data?.coApplicant?.citizenship?.countries ?? [],
                otherCountry:
                  data?.coApplicant?.citizenship?.otherCountry ?? null,
              },
              usCitizen: data?.coApplicant?.usCitizen || false,
              usTaxID: data?.coApplicant?.usTaxID || null,
              pepSelf: data?.coApplicant?.pepSelf,
              pepContact: data?.coApplicant?.pepContact === "yes" || false,
              hasOtherTaxResidences:
                data?.coApplicant?.hasOtherTaxResidences ===
                  "other_countries" || false,
              otherTaxResidences: data?.coApplicant?.otherTaxResidences ?? [],
            }
          : null,
      };

      if (input.autogiroSetup === true) {
        input.autogiro.autogiroConsent = data.autogiroConsent;
        input.autogiro.autogiroConsentText = data.autogiroConsentText;
      }

      sendCompletionData(input);
    },
    [hasCoApplicant, hasLoansToResolve, sendCompletionData]
  );

  useEffect(() => {
    const showContent = () => {
      setfadeIn(true);
      setReviewInit(true);

      if (!agreeTerms) {
        setAgreedTerms(false);
      }

      if (autogiroSetup === "yes" && !autogiroConsent) {
        setAgreedTerms(false);
      }
    };

    if (submitForm) {
      formMethods.handleSubmit(onSubmit)();
      toggleFormSubmitted(false);
      setFinishSteps(false);
    }

    const passSubmit = () => {
      if (finishSteps) {
        handleFinish(finishSteps);
      }
    };

    showContent();
    passSubmit();
  }, [
    submitForm,
    finishSteps,
    handleFinish,
    toggleFormSubmitted,
    onSubmit,
    formMethods,
    setReviewInit,
    agreeTerms,
    autogiroConsent,
    autogiroSetup,
    setAgreedTerms,
  ]);

  return (
    <Fade in={fadeIn} timeout={{ appear: 100, enter: 1000, exit: 1500 }}>
      <StepContent>
        {loading && <Spinner overlay />}
        <Section>
          <Grid item xs={12}>
            <StepHeading
              title={<SanitizedHtml html={interfaceText?.reviewInfoTitle} />}
              helpText={
                interfaceText?.reviewInfoHelp && (
                  <HelpTooltip
                    text={sanitizedString(interfaceText?.reviewInfoHelp)}
                  />
                )
              }
            />
          </Grid>

          <Grid item md={8}>
            <StepPreamble>
              <SanitizedHtml
                html={interfaceText?.reviewInfoDescription || ""}
              />
            </StepPreamble>
          </Grid>
        </Section>

        <FormProvider {...formMethods}>
          <StepSectionPaddingTop>
            <Controller
              control={control}
              name='hasCoApplicant'
              defaultValue={hasCoApplicant}
              render={() => {
                return null;
              }}
            />

            <Container disableGutters={isSmallScreen}>
              <Grid
                container
                component='form'
                onSubmit={formMethods.handleSubmit(onSubmit)}
                justifyContent='center'
              >
                {hasLoansToResolve && (
                  <ReviewBlock
                    header={{
                      title:
                        interfaceText?.loanRepaymentTitle ||
                        "Lån som ska lösas",
                      step: 0,
                      goToStep: props.editStep,
                    }}
                  >
                    <Grid item md={7} sm={10}>
                      {hasInternalLoan && (
                        <InternalLoan
                          loanAmount={internalLoanAmount}
                          labelBankField={
                            interfaceText?.loanRepaymentBankNameLabel
                          }
                          labelAmountField={
                            interfaceText?.loanRepaymentAmountToRepayLabel
                          }
                        />
                      )}
                      {loanRepayments.length > 0 &&
                        loanRepayments.map((item, index) => {
                          return (
                            <LoanFormControls
                              item={item}
                              hasInternalLoan={hasInternalLoan}
                              index={index}
                              inReview={true}
                              debtSaved={true}
                              key={index}
                            />
                          );
                        })}
                    </Grid>
                  </ReviewBlock>
                )}

                <ReviewBlock
                  header={{
                    title: interfaceText?.citizenshipTitle || "Medborgarskap",
                    step: hasLoansToResolve ? 1 : 0,
                    goToStep: props.editStep,
                  }}
                >
                  <Container disableGutters={!isSmallScreen}>
                    <CitizenshipFormControls
                      formMethods={formMethods}
                      inReview
                    />
                  </Container>
                </ReviewBlock>

                <ReviewBlock
                  header={{
                    title:
                      interfaceText?.otherTaxDomicileTitle ||
                      "Skatterättslig hemvist",
                    step: hasLoansToResolve ? 2 : 1,
                    goToStep: props.editStep,
                  }}
                >
                  <Container disableGutters={!isSmallScreen}>
                    <TaxesRadioButtonsGroup classes={classes} inReview />
                  </Container>

                  {otherTaxResidences.length > 0 &&
                    otherTaxResidences.map((item, index) => {
                      return (
                        <TaxesFormControls
                          item={item}
                          key={index}
                          index={index}
                          inReview={true}
                          defaultValues={item}
                        />
                      );
                    })}
                </ReviewBlock>

                {pepQuestions && (
                  <ReviewBlock
                    header={{
                      title:
                        interfaceText?.moreAboutYouTitle ||
                        "Mer kunskap om dig",
                      step: hasLoansToResolve ? 3 : 2,
                      goToStep: props.editStep,
                    }}
                  >
                    <Container disableGutters={!isSmallScreen}>
                      <MoreAboutYouFormComponent
                        inReview={true}
                        formMethods={formMethods}
                      />
                    </Container>
                  </ReviewBlock>
                )}

                {!hasLsbLoanApplication && (
                  <Controller
                    name='autogiroSetup'
                    control={control}
                    defaultValue={autogiroSetup}
                    render={() => null}
                  />
                )}

                {autogiroSetup === "yes" && (
                  <Controller
                    name='autogiroSetup'
                    control={control}
                    defaultValue={autogiroSetup}
                    render={() => null}
                  />
                )}

                {hasLsbLoanApplication &&
                  autogiro?.applicantType !==
                    autogiroTypes.payer.co_applicant && (
                    <ReviewBlock
                      header={{
                        title: interfaceText?.autogiroTitle || "Betalsätt",
                        step: hasLoansToResolve ? 4 : 3,
                        goToStep: props.editStep,
                      }}
                    >
                      <Container disableGutters={!isSmallScreen}>
                        <AutogiroController
                          control={control}
                          autogiro={autogiro}
                        />

                        {autogiroSetup === "no" && (
                          <SetupAutogiroFieldset
                            formMethods={formMethods}
                            inReview
                          />
                        )}
                      </Container>
                    </ReviewBlock>
                  )}
              </Grid>
            </Container>
          </StepSectionPaddingTop>

          {completionData?.hasCoApplicant && (
            <CoApplicantSection
              inReview
              disableGutters={isSmallScreen}
              title='Granska medsökandes uppgifter'
            >
              <ReviewBlock
                coApplicant
                header={{
                  title: "Medborgarskap",
                  step: hasLoansToResolve ? 1 : 0,
                  goToStep: props.editStep,
                }}
              >
                <Container disableGutters={!isSmallScreen}>
                  <CoApplicantCitizenshipFormControls
                    formMethods={formMethods}
                    inReview
                  />
                </Container>
              </ReviewBlock>

              <ReviewBlock
                coApplicant
                header={{
                  title:
                    interfaceText?.otherTaxDomicileTitle ||
                    "Skatterättslig hemvist",
                  step: hasLoansToResolve ? 2 : 1,
                  goToStep: props.editStep,
                }}
              >
                <Container disableGutters={!isSmallScreen}>
                  <CoApplicantTaxesRadioButtonsGroup
                    classes={classes}
                    inReview
                  />
                </Container>

                {coApplicant?.otherTaxResidences &&
                  coApplicant?.otherTaxResidences.length > 0 &&
                  coApplicant?.otherTaxResidences.map((item, index) => {
                    return (
                      <CoApplicantTaxesFormControls
                        item={item}
                        key={index}
                        index={index}
                        inReview={true}
                        defaultValues={item}
                      />
                    );
                  })}
              </ReviewBlock>

              {coApplicant?.pepSelf !== null && (
                <ReviewBlock
                  coApplicant
                  header={{
                    title: "Mer kunskap om din medsökande",
                    step: hasLoansToResolve ? 3 : 2,
                    goToStep: props.editStep,
                  }}
                >
                  <Container disableGutters={!isSmallScreen}>
                    <CoApplicantMoreAboutYouFormComponent
                      inReview={true}
                      formMethods={formMethods}
                    />
                  </Container>
                </ReviewBlock>
              )}

              {hasLsbLoanApplication &&
                autogiroSetup === "yes" &&
                autogiro?.applicantType ===
                  autogiroTypes.payer.co_applicant && (
                  <ReviewBlock
                    coApplicant
                    header={{
                      title: interfaceText?.autogiroTitle || "Betalsätt",
                      step: hasLoansToResolve ? 4 : 3,
                      goToStep: props.editStep,
                    }}
                  >
                    <Container disableGutters={!isSmallScreen}>
                      <AutogiroController
                        control={control}
                        autogiro={autogiro}
                      />
                    </Container>
                  </ReviewBlock>
                )}
            </CoApplicantSection>
          )}
          <StepSectionPaddingTop>
            <Container disableGutters={isSmallScreen}>
              <Grid container justifyContent='center'>
                <Grid item xs={12} sm={10}>
                  <ConsentBlock>
                    {autogiroSetup === "yes" && (
                      <AutogiroConsent
                        formMethods={formMethods}
                        fieldName='autogiroConsent'
                        handleOnClose={agreedAutogiro}
                        label={
                          <SanitizedHtml
                            html={interfaceText?.autogiroConsentLabel}
                            tags={["p", "a", "h2", "h3", "strong"]}
                          />
                        }
                        text={interfaceText?.autogiroConsentText}
                        textFieldName='autogiroConsentText'
                      />
                    )}

                    <ConsentControl
                      formMethods={formMethods}
                      label='Jag intygar att samtliga angivna uppgifter är korrekta och
                        sanningsenliga.'
                      fieldName='termsAndConditionsAgreement'
                      handleOnChange={handleAgreeChange}
                    />
                  </ConsentBlock>
                </Grid>
              </Grid>
            </Container>
          </StepSectionPaddingTop>
        </FormProvider>
      </StepContent>
    </Fade>
  );
};
