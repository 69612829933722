import React from "react";
import { styled } from "@mui/material";

const AlertRoot = styled("div")(({ theme }) => ({
  width: "100%",
}));

const AlertContent = styled("div")(({ theme, type }) => ({
  borderRadius: "8px",
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  color: theme.palette.neutral.light,
  backgroundColor:
    type === "warning"
      ? theme.palette.backgrounds.warning
      : theme.palette.neutralColor[4],
}));

const AlertTitle = styled("span")(({ theme }) => ({
  display: "block",
  fontSize: "1rem",
  fontWeight: 700,
  marginBottom: theme.spacing(1.5),
}));

const AlertMessage = styled("p")(({ theme }) => ({
  margin: 0,
  fontSize: "0.875rem",
  fontWeight: 500,
  lineHeight: 1.429,
  letterSpacing: 0.26,
}));

export const Alert = ({ title, message = "", type = "warning" }) => {
  return (
    <AlertRoot>
      <AlertContent type={type}>
        {title && <AlertTitle>{title}</AlertTitle>}

        <AlertMessage>{message}</AlertMessage>
      </AlertContent>
    </AlertRoot>
  );
};
