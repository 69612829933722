import { autogiroTypes } from "../../config/autogiroTypes";

export const autogiroInputLabels = accountType => {
  switch (accountType) {
    case autogiroTypes.account.both:
      return {
        legend: "Utbetalnings- och återbetalningskonto",
      };

    case autogiroTypes.account.payor:
      return {
        legend: "Utbetalningskonto",
      };
    case autogiroTypes.account.peyee:
      return {
        legend: "Inbetalningskonto",
      };

    default:
      return {
        legend: "Välj bankkonto",
      };
  }
};
