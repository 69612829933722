import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { useState } from "react";
import { PrimaryButton } from "../../CTAButtons/CTAButtons";
import { sanitizedString } from "../../helpers/SanitizedString";
import { HelpTooltip } from "../../HelpTooltip/HelpTooltip";
import { HighlightedBlock } from "../../Layout/HighlightedBlock";

/** */
// styled components are used in AccountsList component
/** */
const ButtonsGroup = styled("div")(theme => ({
  display: "flex",
  gap: "1rem",
  justifyContent: "left",
}));

/** */
// AccountsList component
/** */

export const AccountsList = ({
  accountTypes,
  accounts,
  interfaceText,
  onCancel,
  onSaveAutogiro,
}) => {
  const [value, setValue] = useState(null);

  const handleOnChange = (e, type) => {
    setValue(prev => ({ ...prev, [type]: e.target.value }));
  };

  const interfaceTexts = accountType => {
    switch (accountType) {
      case "BOTH":
        return {
          legend: interfaceText?.accountRepaymentLegend,
          help: interfaceText?.accountRepaymentHelp,
        };

      case "PAYOR":
        return {
          legend: "Inbetalningskonto",
          help: null,
        };
      case "PAYEE":
        return {
          legend: "Utbetalningskonto",
          help: null,
        };

      default:
        return {
          legend: "Välj bankkonto",
          help: null,
        };
    }
  };

  return (
    <HighlightedBlock className='contained'>
      {accountTypes.map((type, index) => {
        let texts = interfaceTexts(type);
        let id =
          "autogiroAccount" + type[0].toUpperCase + type.substring[1] + index;
        return (
          <FormControl component='fieldset' color='secondary' key={index}>
            <FormLabel component='legend' htmlFor={id} id={id + "Label"}>
              {sanitizedString(texts?.legend)}

              {texts?.help && (
                <HelpTooltip inline text={sanitizedString(texts?.help)} />
              )}
            </FormLabel>

            <RadioGroup
              id={id}
              name={id}
              aria-label={texts?.legend || "Välj ett bankkonto"}
              onChange={e => {
                handleOnChange(e, type);
              }}
              defaultValue={value !== null && value[type] ? value[type] : null}
              value={value !== null && value[type] ? value[type] : null}
            >
              {accounts.map((account, index) => (
                <FormControlLabel
                  key={index}
                  value={account.accountNumber}
                  control={<Radio size='small' color='secondary' />}
                  label={account.accountName.concat(
                    " (",
                    account.accountNumber,
                    ")"
                  )}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );
      })}

      <ButtonsGroup>
        <PrimaryButton
          variant='contained'
          sx={{
            width: "auto",
            paddingLeft: "1.5rem",
            paddingRight: "1.5rem",
            margin: 0,
          }}
          disabled={value === null}
          onClick={() => onSaveAutogiro(value)}
        >
          Spara
        </PrimaryButton>

        <PrimaryButton
          variant='outlined'
          sx={{
            width: "auto",
            paddingLeft: "1.5rem",
            paddingRight: "1.5rem",
            margin: 0,
          }}
          onClick={() => onCancel()}
        >
          Avbryt
        </PrimaryButton>
      </ButtonsGroup>
    </HighlightedBlock>
  );
};
