import { styled } from "@mui/system";

export const HighlightedBlock = styled("div")(({ theme, className }) => ({
  position: "relative",
  boxSizing: "border-box",
  padding:
    className === "contained" && `${theme.spacing(4)} ${theme.spacing(6)}`,
  backgroundColor: className === "contained" && "#F7F7F7",
  border: className === "contained" && "1px solid #E5E6E5",
  borderRadius: className === "contained" && "4px",
  marginBottom: theme.spacing(3),

  [theme.breakpoints.up("sm")]: {
    marginBottom: theme.spacing(4),
    borderRadius: className === "contained" && "4px",
  },

  [theme.breakpoints.down("xs")]: {
    borderLeft: 0,
    borderRight: 0,
  },
}));
