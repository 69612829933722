import { gql } from "@apollo/client";

export const UPDATE_COMPLETION_DATA = gql`
  mutation updateCompletion($input: CompletionInput!) {
    updateCompletion(input: $input) {
      data {
        hasCoApplicant
        citizenship {
          countries
          otherCountry
        }
        extraInformation {
          firstName
          lastName
          personalNumber
        }
        hasOtherTaxResidences
        loanRepayments {
          bank
          data {
            amountToRepay
            bankName
            bankPlusGiro
            cardNumber
            ocrNumber
            otherBankName
          }
          id
        }
        otherTaxResidences {
          countrycode
          id
          otherCountry
          taxID
        }
        pepContact
        pepSelf
        requiresLoanRepayment
        status
        statusInterfaceText {
          label
          title
          description
        }
        totalAmountToRepay
        minimumAmountToRepay
        approvedAmount
        feesAmount
        usCitizen
        usTaxID
        hasLsbLoanApplication
        hasInternalLoanRepayment
        requiredResolvedInternalAmount
        autogiroSetup
        autogiroInitUrl
        autogiro {
          applicantType
          bankName
          accounts {
            accountNumber
            accountName
            accountType
            entityId
          }
        }
        coApplicant {
          extraInformation {
            firstName
            lastName
            personalNumber
          }
          citizenship {
            countries
            otherCountry
          }
          hasOtherTaxResidences
          otherTaxResidences {
            countrycode
            id
            otherCountry
            taxID
          }
          pepContact
          pepSelf
          usCitizen
          usTaxID
        }
      }
      errors
    }
  }
`;
